import store from "@/store";

function loggedInGuard(to, from, next) {
  if (store.state.auth.loggedIn) {
    next();
  } else {
    // console.log("guard 1");
    store
      .dispatch("auth/validate")
      .then((/* result */) => {
        // console.log("guard 1.1");
        next();
      })
      .catch((/* error */) => {
        // console.log("guard 1.2");
        let loginPath = window.location.pathname;
        if (loginPath === "/login") {
          next({ name: "login" });
        } else {
          next({ name: "login", query: { redirect: loginPath } });
        }
      });
  }
}

export { loggedInGuard };
