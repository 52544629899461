<template>
  <div data-cy="comp-logged-in">
    <v-menu v-model="showMenu" close-on-content-click bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-sheet
          class="d-flex align-center"
          color="transparent"
          v-bind="attrs"
          v-on="on"
        >
          <v-avatar color="primary" class="mr-1">
            <v-icon large dark>mdi-account-circle</v-icon>
          </v-avatar>
          <div>{{ user.name }}</div>
        </v-sheet>
      </template>
      <v-list>
        <v-list-item
          v-if="hasImpersonations"
          @click="showImpersonations = true"
          data-cy="menu-user-impersonations"
        >
          <v-list-item-action>
            <v-icon>$impersonation</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("auth.impersonations")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout" data-cy="menu-user-logout">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("auth.signOut") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Impersonations dialog -->
    <v-dialog v-model="showImpersonations">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $t("auth.impersonations") }}
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(item, index) in impersonationsFrom"
              :key="index"
            >
              <v-list-item-action>
                <v-icon>mdi-account</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  >{{ item.organisation_name }} - {{ item.user_name }}
                  {{ item.user_surnames }}</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click.prevent="clickImpersonate(item)">
                  <v-icon>$impersonation</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showImpersonations = false">
            {{ $t("common.cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";

export default {
  name: "UsersLoggedIn",
  mixins: [ComponentStatus],
  components: {},
  data: () => ({
    showMenu: false,
    showImpersonations: false,
  }),
  computed: {
    loggedInUserId() {
      return this.$store.getters["auth/getUserId"];
    },
    impersonationsFrom() {
      return this.$store.getters["auth/getImpersonationsFrom"];
    },
    user() {
      return this.$store.getters["users/readById"](this.loggedInUserId);
    },
    hasImpersonations() {
      return this.impersonationsFrom.length > 0;
    },
  },
  watch: {
    loggedInUserId: {
      immediate: true,
      handler: function (newValue) {
        if (newValue !== -1) {
          this.setStatusReading();
          this.$store
            .dispatch("users/readById", newValue)
            .then((/* result */) => {
              this.setStatusReadSuccess();
            })
            .catch((/* error */) => {
              this.setStatusReadError();
              this.$store.commit("status/showError");
            });
        }
      },
    },
  },
  created() {},
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push({ name: "login" });
      });
    },
    clickImpersonate(impersonation) {
      this.$store
        .dispatch("auth/impersonateTo", {
          to_user_id: impersonation["to_user_id"],
          to_organisation_id: impersonation["to_organisation_id"],
        })
        .then((/* result */) => {
          this.showImpersonations = false;
          this.setStatusReadSuccess();
          if (
            this.$route.name === "home" ||
            this.$route.name === "documents-index"
          ) {
            this.$router.go();
          } else {
            this.$router.push({ name: "home" });
          }
        })
        .catch((/* error */) => {
          this.showImpersonations = false;
          this.setStatusReadError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>
