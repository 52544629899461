import Vue from "vue";
import axios from "axios";
import Model from "./model";

const getDefaultState = () => {
  return {
    itemsKeys: [],
    items: {},
  };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
  readAll(state) {
    return state.itemsKeys
      .filter((id) => {
        if (id && state.items[id].temp === false) {
          return true;
        }
        return false;
      })
      .map((id) => {
        return state.items[id];
      });
  },
  readById(state) {
    return (id) => {
      if (id === undefined) {
        return new Model();
      } else {
        if (!(id in state.items)) {
          Vue.set(state.items, id, new Model());
        }
        return state.items[id];
      }
    };
  },
};

// actions
const actions = {
  resetState({ commit }) {
    commit("resetState");
  },
  create({ commit }, payload) {
    let forApi = new Model(payload);
    forApi = forApi.filterForApi();
    forApi.filterOutEmptyKeys();
    return axios({
      method: "POST",
      url: `${process.env.VUE_APP_API_URL}/folders`,
      headers: { "content-type": "application/json" },
      data: forApi,
    }).then(
      (result) => {
        // console.log('create', result)
        commit("createOnSuccess", result.data);
        return result.data.id;
      },
      (error) => {
        // console.error('ERROR create', error);
        throw error;
      }
    );
  },
  readAll({ commit }) {
    return axios({
      method: "GET",
      url: `${process.env.VUE_APP_API_URL}/folders`,
    }).then(
      (result) => {
        // console.log('readAll', result)
        commit("readAllSuccess", result.data);
        return true;
      },
      (error) => {
        // console.error('ERROR readAll', error)
        throw error;
      }
    );
  },
  readById({ commit }, id) {
    if (id) {
      return axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/folders/${id}`,
      }).then(
        (result) => {
          // console.log('readById', result)
          commit("readByIdSuccess", result.data);
          return true;
        },
        (error) => {
          // console.error('ERROR readById', error)
          throw error;
        }
      );
    } else {
      return new Promise((resolve, reject) => {
        reject("Not enough parameters");
      });
    }
  },
  update({ commit }, payload) {
    if ("id" in payload && payload.id) {
      let forApi = new Model(payload);
      forApi = forApi.filterForApi();

      return axios({
        method: "PATCH",
        url: `${process.env.VUE_APP_API_URL}/folders/${forApi.id}`,
        headers: { "content-type": "application/json" },
        data: forApi,
      }).then(
        (result) => {
          // console.log('update', result)
          commit("updateOnSuccess", result.data);
          return true;
        },
        (error) => {
          // console.error('ERROR update', error)
          throw error;
        }
      );
    } else {
      return new Promise((resolve, reject) => {
        reject("Not enough parameters");
      });
    }
  },
  updatePermissions(ignore, id) {
    if (id) {
      let forApi = { id: id };

      return axios({
        method: "PATCH",
        url: `${process.env.VUE_APP_API_URL}/folders/${forApi.id}/permissions-desc`,
        headers: { "content-type": "application/json" },
        data: forApi,
      }).then(
        () => {
          // console.log('update', result)
          return true;
        },
        (error) => {
          // console.error('ERROR update', error)
          throw error;
        }
      );
    } else {
      return new Promise((resolve, reject) => {
        reject("Not enough parameters");
      });
    }
  },
  delete({ commit }, id) {
    if (id) {
      return axios({
        method: "DELETE",
        url: `${process.env.VUE_APP_API_URL}/folders/${id}`,
      }).then(
        (/* result */) => {
          // console.log('delete', result)
          commit("deleteOnSuccess", id);
          return true;
        },
        (error) => {
          // console.error('ERROR delete', error)
          throw error;
        }
      );
    } else {
      return new Promise((resolve, reject) => {
        reject("Not enough parameters");
      });
    }
  },
};

// mutations
const mutations = {
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  },
  createOnSuccess(state, item) {
    let forStore = new Model(item);
    forStore = forStore.filterForStore();

    if (!(forStore.id in state.items)) {
      Vue.set(state.items, forStore.id, new Model());
      state.itemsKeys.push(forStore.id);
    }
    Vue.set(
      state.items,
      forStore.id,
      Object.assign(new Model(), state.items[forStore.id], forStore, {
        temp: false,
      })
    );
  },
  readAllSuccess(state, items) {
    let readAllKeys = [];
    for (let i = 0; i < items.length; i++) {
      if ("id" in items[i] && items[i].id) {
        let forStore = new Model(items[i]);
        forStore = forStore.filterForStore();

        Vue.set(
          state.items,
          forStore.id,
          Object.assign(new Model(), state.items[forStore.id], forStore, {
            temp: false,
          })
        );
        readAllKeys.push(forStore.id);
        if (state.itemsKeys.indexOf(forStore.id) < 0) {
          state.itemsKeys.push(forStore.id);
        }
      }
    }

    let toDelete = state.itemsKeys.filter(function (key) {
      return readAllKeys.indexOf(key) < 0;
    });
    for (let i = 0; i < toDelete.length; i++) {
      Vue.delete(state.items, toDelete[i]);
      state.itemsKeys.splice(state.itemsKeys.indexOf(toDelete[i]), 1);
    }
  },
  readByIdSuccess(state, item) {
    let forStore = new Model(item);
    forStore = forStore.filterForStore();

    if (!(forStore.id in state.items)) {
      Vue.set(state.items, forStore.id, new Model());
    }
    // When we create a temporary item we put it on state.items but not on
    // state.itemKeys as we don't know if it really exists or not. When we
    // receive confirmation that the item exists on the backend then we
    // can put it on state.itemKeys.
    if (state.itemsKeys.indexOf(forStore.id) < 0) {
      state.itemsKeys.push(forStore.id);
    }
    Vue.set(
      state.items,
      forStore.id,
      Object.assign(new Model(), state.items[forStore.id], forStore, {
        temp: false,
      })
    );
  },
  updateOnSuccess(state, item) {
    let forStore = new Model(item);
    forStore = forStore.filterForStore();

    Vue.set(
      state.items,
      forStore.id,
      Object.assign(new Model(), state.items[forStore.id], forStore, {
        temp: false,
      })
    );
  },
  deleteOnSuccess(state, id) {
    if (id in state.items) {
      Vue.delete(state.items, id);
      state.itemsKeys.splice(state.itemsKeys.indexOf(id), 1);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
