import { loggedInGuard } from "@/modules/base/routeGuards";

export default [
  // {
  //   path: "/documents/:documentId/shares",
  //   name: "documents-shares-index",
  //   beforeEnter: loggedInGuard,
  //   meta: { layout: "authorised" },
  //   component: () => import("./pages/DocumentsSharesIndexPage.vue"),
  // },
  {
    path: "/documents/:documentId/shares/create",
    name: "documents-shares-create",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/DocumentsSharesCreatePage.vue"),
  },
  // {
  //   path: "/documents/:documentId/shares/:id",
  //   name: "documents-shares-show",
  //   beforeEnter: loggedInGuard,
  //   meta: { layout: "authorised" },
  //   component: () => import("./pages/DocumentsSharesShowPage.vue"),
  // },
  {
    path: "/documents/:documentId/shares/:id/edit",
    name: "documents-shares-edit",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/DocumentsSharesEditPage.vue"),
  },
  {
    path: "/documents/:documentId/shares/:id/delete",
    name: "documents-shares-delete",
    beforeEnter: loggedInGuard,
    meta: { layout: "authorised" },
    component: () => import("./pages/DocumentsSharesDeletePage.vue"),
  },

  {
    path: "/shared/:token",
    name: "documents-public-show",
    // beforeEnter: loggedInGuard,
    meta: { layout: "not-authorised" },
    component: () => import("./pages/DocumentsPublicShowPage.vue"),
  },
];
