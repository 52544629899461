import axios from "axios";

const getDefaultState = () => {
  return {
    auth: {
      method: "internal",
      urlCsrfCookie: "",
      urlLogin: "",
      urlLoggedInUserInfo: "",
      urlLogout: "",
      urlValidate: "",
    },
    docudirectorApi: {
      sanctum: "",
      auth: "",
      api: "",
      webApi: "",
    },
  };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
  getAuthMethod(state) {
    return state.auth.method;
  },
  getAuthUrlCsrfCookie(state) {
    return state.auth.urlCsrfCookie;
  },
  getAuthUrlLogin(state) {
    return state.auth.urlLogin;
  },
  getAuthUrlLoggedInUserInfo(state) {
    return state.auth.urlLoggedInUserInfo;
  },
  getAuthUrlLogout(state) {
    return state.auth.urlLogout;
  },
  getAuthUrlValidate(state) {
    return state.auth.urlValidate;
  },
};

// actions
const actions = {
  resetState({ commit }) {
    commit("resetState");
  },
  getConfig({ commit }) {
    return axios({
      method: "GET",
      url: "/config.json",
      headers: { "content-type": "application/json" },
    }).then((result) => {
      commit("getConfigOnSuccess", result.data);
    });
  },
};

// mutations
const mutations = {
  resetState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  },
  getConfigOnSuccess(state, configData) {
    Object.assign(state, getDefaultState());

    if (configData && configData.auth) {
      if (configData.auth.method) {
        state.auth.method = configData.auth.method;

        if (configData.auth.urlCsrfCookie) {
          state.auth.urlCsrfCookie = configData.auth.urlCsrfCookie;
        }
        if (configData.auth.urlLogin) {
          state.auth.urlLogin = configData.auth.urlLogin;
        }
        if (configData.auth.urlLoggedInUserInfo) {
          state.auth.urlLoggedInUserInfo = configData.auth.urlLoggedInUserInfo;
        }
        if (configData.auth.urlLogout) {
          state.auth.urlLogout = configData.auth.urlLogout;
        }
        if (configData.auth.urlValidate) {
          state.auth.urlValidate = configData.auth.urlValidate;
        }
      }
    }
    if (configData && configData.docudirectorApi) {
      if (configData.docudirectorApi.sanctum) {
        state.docudirectorApi.sanctum = configData.docudirectorApi.sanctum;
      }
      if (configData.docudirectorApi.auth) {
        state.docudirectorApi.auth = configData.docudirectorApi.auth;
      }
      if (configData.docudirectorApi.api) {
        state.docudirectorApi.api = configData.docudirectorApi.api;
      }
      if (configData.docudirectorApi.webApi) {
        state.docudirectorApi.webApi = configData.docudirectorApi.webApi;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
